import pre_primary from "./asset/pre-primary.jpg";
import Primarycurriculumouter from "./asset/Primarycurriculumouter.jpeg";

import secondaryOuter from "./asset/secondaryOuter.jpeg";
import sr_secondary from "./asset/sr_secondary.jpg";

//alumni
import nikhel from "./asset/nikhel.jpg";
import scanner from "./asset/scanner.jpg";
import ankita from "./asset/ankita.jpg";
import sandeep from "./asset/sandeep.jpg";
import Azad from "./asset/Azad.jpg";
import Akram from "./asset/Akram.jpg";
import Nirmala from "./asset/Nirmala.jpg";

import bharat_bansal from "./asset/bharat_bansal.jpg";
import subhadeep from "./asset/subhadeep.jpg";
import karan from "./asset/karan.jpg";
//
import JinsiPhoto from "./asset/newImg/Jinsi-picture.png";
import SumantaPhoto from "./asset/newImg/Sumanta.jpg";
import DebarotiPhoto from "./asset/debaroti.png";
import RameshKumar from "./asset/ramesh.png";
import Headmaster from "./asset/Headmaster.jfif";

//
import result_10 from "./asset/result-10.jpg";
import result_10_1 from "./asset/result-10-1.jpg";
import result_10_2 from "./asset/result-10-2.jpg";
import result_10_3 from "./asset/result-10-3.jpg";
import result_12_1 from "./asset/result-12-1.jpg";
import result_12_2 from "./asset/result-12-2.jpg";
import result_12_3 from "./asset/result-12-3.jpg";

//
import laboratories from "./asset/laboratories.jpg";
import library from "./asset/library.jpg";
//
import life_skill_1 from "./asset/life_skill_1.jpg";
import life_skill_2 from "./asset/life_skill_2.jpg";
import vocational_1 from "./asset/vocational_1.jpg";
import vocational_2 from "./asset/vocational_2.jpg";
import skill_based from "./asset/skill_based.jpg";
import skill_based_2 from "./asset/skill_based_2.jpg";
import events_cover from "./asset/AnnualSports.jpeg";
import annual_1 from "./asset/annual_1.jpg";
import annual_2 from "./asset/annual_2.jpg";
import annual_3 from "./asset/annual_3.jpg";
import teacher from "./asset/teacher.jpg";
import inter from "./asset/inter.jpeg";
import sports_club from "./asset/SportsClub.png";
import environment_club from "./asset/environmentclub.jpeg";
import expression_club from "./asset/expression_club.jpg";
import student_council_1 from "./asset/student_council_1.jpeg";
import student_council_2 from "./asset/student_council_2.jpg";
import student_council_3 from "./asset/student_council_3.jpg";
import ncc_1 from "./asset/NccPara.jpeg";
import school_band_1 from "./asset/school_band_1.jpg";
import scouts_1 from "./asset/scouts_1.jpg";
import bus_1 from "./asset/bus_1.jpg";
import award_1 from "./asset/award_1.jpg";
import award_2 from "./asset/award_2.jpg";
import award_3 from "./asset/award_3.jpg";
import award_4 from "./asset/award_4.jpg";
import award_5 from "./asset/award_5.jpg";
import award_6 from "./asset/award_6.jpg";
import award_7 from "./asset/award_7.jpg";
import award_8 from "./asset/award_8.jpg";
import award_9 from "./asset/award_9.jpg";
import award_10 from "./asset/award_10.jpg";
import pre_primary_1 from "./asset/pre_primary_1.jpg";
import pre_primary_2 from "./asset/pre_primary_2.jpg";
import primary_1 from "./asset/primary_1.jpg";
import SecondaryIneer1 from "./asset/SecondaryIneer1.jpeg";
import secondary_2 from "./asset/secondary_2.jpg";
import sr_secondary_1_1 from "./asset/sr_secondary_1_1.jpg";
import sr_secondary_2 from "./asset/sr_secondary_2.jpg";
import career_1 from "./asset/career_1.jpg";
import career_2 from "./asset/career_2.jpg";
import career_4 from "./asset/career_4.jpg";
import career_5 from "./asset/career_5.jpg";
// import sports_1 from "./asset/sports_1.jpg";
import sports_3 from "./asset/sports_3.jpg";
import arts_club from "./asset/arts_club.jpg";
import playground_1 from "./asset/playground_1.jpg";
import PlayGround_2 from "./asset/Play Ground-2.jpg";
import vision from "./asset/vision.jpg";
import mission from "./asset/mission.jpg";
import anti_bullying from "./asset/HealthClub.png";
import health_1 from "./asset/health_1.jpg";
import health_2 from "./asset/health_2.jpg";
import value_club from "./asset/value_club.jpg";
import social_impact from "./asset/socialImpactclub.jpeg";
import gender_sensitivity from "./asset/gender_sensitivity.jpg";
import english_club from "./asset/english_club.jpg";
import budding_1 from "./asset/budding_1.jpg";
import budding_2 from "./asset/budding_2.jpg";
import sdmc from "./asset/sdmc.jpg";
import sdmc_2 from "./asset/sdmc_2.jpg";
import house_system from "./asset/house_system.jpg";
import trip_1 from "./asset/trip_1.jpg";
import trip_cover from "./asset/t1.jpeg";
import trip_cover_1 from "./asset/t3.jpeg";
import trip_cover_3 from "./asset/trip3.jpeg";
// import trip_4 from "./asset/trip_4.jpeg";
import PrimaryPara1 from "./asset/PrimaryPara1.jpeg";
import PrimaryPara2 from "./asset/PrimaryPara2.jpeg";
import PrimaryPara3 from "./asset/PrimaryPara3.jpeg";
import experimental_learning from "./asset/experimental_learning.jpg";
import spacious_classroom from "./asset/spacious_classroom.jpg";
import spacious_classroom_2 from "./asset/spacious_classroom_2.jpg";
import stem_lab from "./asset/stem_lab.jpg";
import stem_lab_2 from "./asset/stem_lab_2.jpg";
import computer_lab_1 from "./asset/computer_lab_1.jpg";
import computer_lab_2 from "./asset/computer_lab_2.jpg";
import laboratories_2 from "./asset/laboratories_2.jpg";
import home_science from "./asset/home_science.jpg";
import home_science_2 from "./asset/home_science_2.jpg";
import arts from "./asset/arts.jpg";
import arts_2 from "./asset/arts_2.jpg";
import library_2 from "./asset/library_2.jpg";
import cultural from "./asset/cultural.jpg";
import cultural_2 from "./asset/cultural_2.jpg";
import cultural_3 from "./asset/cultural_3.jpg";
import cultural_4 from "./asset/cultural_4.jpg";
import rain from "./asset/rain_water.jpg";
import solar from "./asset/solar.jpeg";
import bio_lab from "./asset/bio_lab.jpg";
import chem_lab from "./asset/chem_lab.jpg";
import phy_lab from "./asset/phy_lab.jpg";
import infrastructure_1 from "./asset/infrastructure_1.jpg";
import faculty_1 from "./asset/newImg/1.png";
import faculty_2 from "./asset/newImg/2.png";
import faculty_3 from "./asset/newImg/3.png";
import faculty_4 from "./asset/newImg/4.png";
import faculty_5 from "./asset/newImg/5.png";
import faculty_6 from "./asset/newImg/6.png";
import aa from "./asset/newImg/aa.jpeg";
import a from "./asset/newImg/a.jpg";
import b from "./asset/newImg/b.jpg";
import c from "./asset/newImg/c.jpg";
import d from "./asset/newImg/d.jpg";
import e from "./asset/newImg/e.jpg";

import pre1 from "./asset/newImg/Preprimary (1).jpeg";
import pre2 from "./asset/newImg/Preprimary (2).jpeg";

import red from "./asset/newImg/red1.jpeg";
import blue from "./asset/newImg/blue1.jpeg";
import yellow from "./asset/newImg/yellow1.jpeg";
import green from "./asset/newImg/green1.jpeg";

import expression from "./asset/newImg/expression.png";
import ba from "./asset/newImg/ba.png";

import media1 from "./asset/newImg/media/2.jpg";
import m1 from "./asset/media1.jpeg";
import m2 from "./asset/media2.jpeg";
import m3 from "./asset/media3.jpeg";
import m4 from "./asset/media4.jpeg";
import m6 from "./asset/m6.jpg";
import m7 from "./asset/m7.jpg";
import m8 from "./asset/m8.jpeg";
import m9 from "./asset/m9.jpeg";
import media2 from "./asset/newImg/media/4.jpg";
import media3 from "./asset/newImg/media/5.jpg";
import media4 from "./asset/newImg/media/6.jpg";
import media5 from "./asset/newImg/media/7.jpg";
import media from "./asset/newImg/media/7.jpg";
import m10 from "./asset/newImg/media/media17.jpeg";
import m11 from "./asset/newImg/media/22.jpg";

import sports_club_icon from "./asset/clubs/sportsClubIcon.png";
import anti_bullying_icon from "./asset/clubs/antiBullyingIcon.png";
import environment_club_icon from "./asset/clubs/environmentClubIcon.png";
import health_club_icon from "./asset/clubs/healthClubIcon.png";
import value_club_icon from "./asset/clubs/valueClubIcon.png";
import social_impact_club_icon from "./asset/clubs/socialImpactClub.png";
import gender_club from "./asset/clubs/genderClub.png";
import english_club_icon from "./asset/clubs/englishClub.png";

//
import orientation_1 from "./asset/orientation_1.jpg";
import orientation_2 from "./asset/orientation_2.jpg";
import orientation_3 from "./asset/orientation_3.jpg";
import orientation_4 from "./asset/orientation_4.jpg";
import book from "./asset/book.jpg";
import waste from "./asset/waste.jpg";
import waste_2 from "./asset/waste_2.jpg";
import paper from "./asset/paper.jpg";
import republic_1 from "./asset/republic_1.jpg";
import republic_2 from "./asset/republic_2.jpg";
import republic_3 from "./asset/republic_3.jpg";
import covid from "./asset/covid.jpg";
import mother from "./asset/mother.jpg";
import first_aid from "./asset/first_aid.jpg";
import basant_1 from "./asset/basant_1.jpg";
import basant_2 from "./asset/basant_2.jpg";
import pareeksha from "./asset/pareeksha.jpg";
import pareeksha_2 from "./asset/pareeksha_2.jpg";
import heritage from "./asset/heritage.jpg";
import farewell from "./asset/farewell.jpg";
import farewell_2 from "./asset/farewell_2.jpg";
import environment from "./asset/environment.jpg";
import fashion from "./asset/fashion.jpg";
import fire from "./asset/fire.jpg";
import donation_1 from "./asset/donation_1.jpg";
import donation_2 from "./asset/donation_2.jpg";

import { FaSchool } from "react-icons/fa";
//
export const sidebar_data_vision = [
  {
    title: "Vision & Mission",
    navigation: "vision-and-mission",
  },
  {
    title: "Message From Leadership",
    navigation: "message",
  },
  {
    title: "Academics & Curriculum",
    navigation: "nep",
  },
  {
    title: "Beyond Curriculum",
    navigation: "house-clubs",
  },
  {
    title: "Sports & Fitness",
    navigation: "sports",
  },
  {
    title: "Faculty & Staff",
    navigation: "faculty-staff",
  },
  {
    title: "Events & Happenings",
    navigation: "events",
  },
  {
    title: "Parents' Voice",
    navigation: "parents",
  },
  {
    title: "Infrastructure & Facilities",
    navigation: "infrastructure",
  },
  // {
  //   title: "Achievements and Laurels",
  //   navigation: "awards",
  // },
  {
    title: "News & Media Coverage",
    navigation: "news",
  },
  {
    title: "Admissions",
    navigation: "admissions",
  },
];
export const academics_sidenav = [
  {
    title: "NEP 2020 Ready School ",
    navigation: "nep",
  },
  {
    title: "Curriculum ",
    navigation: "curriculum",
  },
  {
    title: "Pedagogical Approach",
    navigation: "pedagogi",
  },
  {
    title: "Life Skill Curriculum",
    navigation: "life-skill",
  },
  {
    title: "Assessment",
    navigation: "examination",
  },
  {
    title: "Skill & Vocational Subjects",
    navigation: "skill-vocational",
  },
  {
    title: "Results",
    navigation: "results",
  },
  {
    title: "Booklist",
    navigation: "booklist-uniform",
  },
];
export const beyond_sidenav = [
  {
    title: "Houses & Clubs",
    navigation: "house-clubs",
  },
  {
    title: "Student Council",
    navigation: "student-council",
  },
  {
    title: "Trips, Excursions & Field Visits",
    navigation: "trips",
  },
  {
    title: "NCC, Band, Scouts & Guides",
    navigation: "ncc-scouts",
  },
];
//
export const vision_mission = [
  {
    id: 1,
    title: "Vision",
    description:
      "We at Hermannn Gmeiner School believe in scholarly and accomplished learning. To provide all our students with advanced and qualified academic education along with the knowledge and experience in sports and co-curricular activities has always been our priority. Blending leisure with technology and creating a holistic experience for the educators to have the finest learning experience has been the precedence of our institution. We tend to upskill and upgrade all the children at the primary age with the will and spirit to be the best and brightest version of themselves. This helps them to grow and develop individually and in the society to become a good and contributing citizen of our country. ",
    description_image: [vision],
  },
  {
    id: 2,
    title: "Mission",
    description:
      "The mission of Hermannn Gmeiner School is to create a safe space that nurtures the cerebral, social, emotional, physical, spiritual and academic development of every child. Our school strives to encourage and develop a sense of confidence and self-assurance in every juvenile. With a wide yet safe environment along with empathetic and patient staff, we want to empower our students and help them rise at every step of learning. By inculcating family and society values along with practical and theoretical knowledge, the children would learn the significance of selfhood and brotherhood.",
    description_image: [mission],
  },
];
export const message_data = [
  {
    id: 1,
    title: "President",
    name: "Rakesh Jinsi",
    image: JinsiPhoto,
    description:
      "Rakesh Jinsi, an engineer by degree, has 41 years of experience in leadership roles at several institutions, including SOS Children's Villages. Presently running Khushboo, a daycare centre for children with severe mental and multiple disabilities, he has always been active in national level bodies such  as the National Human Rights Commission and Voluntary Action Network of India (VANI), the Apex body of the NGO sector.",
    extra_description: [
      `"I firmly believe that each and every child deserves an education that is advanced, detail-oriented yet nurturing and holistic. As President of SOS Children’s Villages of India, I wish to provide the best possible learning experience to the future leaders of our country, so that all children are able to reach their maximum potential and achieve their dreams. Students end up spending most of their childhood within classrooms, so it is essential that within those walls they are imparted proper theoretical knowledge, practical aptitude, communal values, essential life skills, and an understanding of how the world works and their place in it. ,
      "Adequate grooming and learning of children at a young age leads to them being strong, confident, hard-working and intelligent individuals, attributes that I deem necessary for the new era of technology and scientific progress we are heading towards."`,
    ],
  },
  {
    id: 2,
    title: "Secretary General",
    name: "Sumanta Kar",
    image: SumantaPhoto,
    description:
      "Education at SOS Hermannn Gmeiner Schools has always meant the Holistic Development of a student – we believe that each child is entitled to the best possible skill set for their future, therefore, each child entrusted with us will receive an education that is in its finest form. ",
    extra_description: [
      "Education is preparation for life, it should be an enjoyable, interactive and ongoing process to make the students confident young adults, sensitive to global issues, committed citizens who are aware of the happenings of the world and sensible individuals with a positive attitude. ",
      "Students should have a strong character and a good understanding of their humane and emotional sides. All our Hermannn Gmeiner Schools have been actively engaged in relating to, understanding and working with communities around our schools.",
      "Safeguarding of children remains a top priority and they are nurtured through a theme-based curriculum and actively engage in experiential learning. Our teachers and principals play a pivotal role in the development of each child. Our alumni are our brand ambassadors, who leave a lasting footprint in whatever profession they choose to pursue. Each student who joins us becomes a future world leader, ready to bring progressive change and make our nation proud.",
    ],
  },
  {
    id: 3,
    title: "Head- Education & Child Development",
    name: "Debaroti Bose",
    image: DebarotiPhoto,
    description:
      "I believe that school should be the experience of a lifetime. Our school experience sets the stage for success later in life and provides a lifetime of positive memories for our students. We are proud of our continuous goal of providing an excellent education for our students.We have a dynamic, hardworking faculty who are involved in education to benefit the students.The entire gamut of school education is transforming the teaching-learning process. ",
    extra_description: [
      "In the modern concept of schooling, teaching has become redundant, paving the way for the facilitation of learning through knowledge sharing. The wave of digital learning and cloud computing is reaching new heights.",
      " In all this whirlwind of new-age technology, it is important to charge ahead but with one’s head firmly on one’s shoulders. Hermannn Gmeiner School strives to inculcate moral values, manners, responsibilities among students so that they may build a world free of all the evils.",
      "Students here are encouraged to dream, groomed to give wings to their dreams, to take off and explore the unexplored. The plethora of interesting & exciting opportunities available to youngsters today often leave them with an array of things to do.",
      " It is important therefore to help them build their focus on the subject that draws them most and gives shape to their vision. A student is made to blossom intellectually, morally, and spiritually. As Swami Vivekananda said, “Take up one idea. Make that one idea your life – think of it, dream of it, and live on that idea. Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is the way to success.”",
    ],
  },
  {
    id: 4,
    title: "Principal",
    name: "Mohd. Aslam",
    image: Headmaster,
    description:
      "The majority of people dream of being successful, yet only a few take this dream forward and work towards realizing the success they desire in their lives. For someone to be successful one needs to be ready, willing, and able to work hard for it. Is it important for a person to know the precise level of success he desires? His goals must also be smart, measurable, achievable, realistic, and time.If a person wants to succeed in life, then nothing can ever stop him from achieving it.  ",
    extra_description: [
      "He must have a strong desire and an equally strong commitment to work towards achieving his objectives, should be very optimistic, with a sharp focus on potential and not problems, and should always visualize the best possible outcome and benefits he wants to achieve and let this motivate and energize him to work towards it.",
      "Hermannn Gmeiner School has set up its tradition of keeping children motivated to channelize their energy towards something export constructive and enriching through Experiential Learning with a strong focus on achieving learning objectives. Students are given projects/ assignments that require them to learn the necessary material themselves.",
      "The key to this is the ability to get the information they need anytime anywhere without being in the physical presence of a facilitator.",
    ],
  },
];
export const infrastructure = [
  {
    id: 1,
    infra_title: "Infrastructure",
    description: `The school, SOS HGS Palla, Nuh was established for the educational upliftment of community children in the
    village of Palla District Nuh (Mewat) Haryana in 1997 as a community project. Later it was affiliated to
    Haryana Board of Secondary Education for Secondary classes in 2005. The school is playing a dominant role
    in imparting education to the needy who are backward in socio-economic level especially in regard to the
    girl’s children. Ever since the school has been established, it has only grown and paved its way to be one of
    the safest and premier educational institutions. To facilitate the students in all the activities, libraries, labs,
    grounds have been built. This would not only help them explore different areas of interest but also help
    them hone their skills.`,
    image: [infrastructure_1],
  },
  {
    id: 1,
    infra_title: "Spacious Classrooms",
    description: `The school boosts up spacious, well-ventilated and excellently furnished classrooms to make the
    students learning comfortable and enjoyable. The furniture were specially designed tables and
    chairs that could be very easily arranged as per required set up for group activities and boosting
    peer learning. In all classrooms display boards too are provided to display creative chart works,
    drawings and paintings and craft works.`,
    image: [spacious_classroom],
  },

  {
    id: 3,
    infra_title: "STEM Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way
    of fostering a diverse STEM workforce. Educators are focused on improving science and
    mathematics instructions employing several approaches to K-12 STEM education. Students also
    were given opportunities to spend time with professionals in STEM fields.
    `,
    image: [stem_lab],
  },

  {
    id: 3,
    infra_title: "Computer Lab",
    description: `The school has a highly sophisticated state of the art computer lab. It is equipped with a
    computing facility and computers connected through the Local Area Network as well as Wi-Fi.
    These facilities are an integral part of the curriculum. Students are expected to use computers in
    their day-to-day study in order to meet the challenges of modern learning and to keep pace with
    global scenarios. `,
    image: [computer_lab_1],
  },
  {
    id: 4,
    infra_title: "Playground ",
    description: `Hermann Gmeiner School Palla offers a large space for various sports and fitness activities.
    From Physical Education periods to different events and tournaments playground cater to all the
    needs and keep the mind and body rejuvenated. It consist of recreational equipment such as the
    seesaw, swing set, slide, many of which help children develop physical coordination, strength and
    flexibility, as well as providing recreation and enjoyment.`,
    image: [PlayGround_2],
  },
  {
    id: 4,
    infra_title: "Library ",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites
    young users to delve into the magical world of words and the wealth of research material
    available. The school has a well-equipped library. The eclectic collection that export constitutes
    the Library currently includes over 7,100 books. The Library resources comprise Encyclopedias,
    Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well
    as specific skills-related books on Personality Development, Time Management, and
    Communication Skills.
    The school library got enriched with the new collection of 2 lac. This was donated
    by Thompson Press India Ltd Faridabad.
    `,
    image: [library],
  },

  {
    id: 5,
    infra_title: "Rain Water Harvesting",
    description: `The school consists of RWH in Senior Wing and Primary Wing both with capacity of 1.5 Lac Ltrs
  water in each setup.`,
    image: [rain],
  },

  {
    id: 6,
    infra_title: "Solar Power",
    description: `The school is empowered with solar system in both the wings- with around 17 KW in senior wing and
  5 KW in primary wing.`,
    image: [solar],
  },
];
export const career_guidance = [
  {
    id: 1,
    title: "Career Counselling Workshop",
    description: `The future depends on what you do today." Hermannn Gmeiner School organised a Career Counseling and Guidance programme to direct the students to move on the right path. Parents from different fields and resource persons were invited to share their journey of success with students so that they could learn different aspects of success. The students engaged in the session, asked queries to have better vision for their future and were satisfied with the guidance.`,
    description_image: [career_1],
  },
  {
    id: 2,
    title: "",
    description: `Career building is a deep rooted process. It becomes tedious for adolescents to choose an appropriate career with great placements and a bright future. School plays an important role in this ever-growing process of finding oneself and establishing oneself.`,
    description_image: [career_2],
  },
  {
    id: 3,
    title: "",
    description: ` ITM Institute of Design & Media conducted a career counselling workshop on career opportunities in online world like “YOUTUBE, VFX, ANIMATION", etc. on Feb 16th ,2022. The key speaker was Ms. Jyotsna Raghunathan, who is a storyteller and a film maker with over 20 years of experience in writing scripts, directing films and working in the educational sector of media and mass communication. She has also specialized in curriculum design and development, as well as making films and writing scripts under her own company banner, Panvision. `,
    description_image: [career_4],
  },
  {
    id: 4,
    title: "",
    description: ` The workshop started with her addressing the issue of confusion being created in youngsters' mind while choosing a career. She talked about the varied courses available for all the streams and how these can be helpful in fulfilling their dreams. The students were attentive and interactive and asked queries like the exams to be taken up, combinations of subjects available, options offered, placements etc. It was an enriching session for the students of grade XII.`,
    description_image: [career_5],
  },
];
export const admission = [
  {
    id: 1,
    title: "Admission Procedure",
    image: false,
    features: [
      "Admissions are granted as and when vacancies are available in any class.",
      "Admission forms are available in the school office on 15 Jan every year from the school office. Registration forms also can be downloaded from the school website.",
      "Admission in classes prep to VIII is granted on a ‘first-come-first-served basis.",
      "For admission in class IX, the entrance exam will be of 2 hrs. in the subjects of Hindi, English, Mathematics, and General Science.",
      "For admission to the class, XI counselling will be conducted for Hermannn Gmeiner School students, and for other students entrance tests will be conducted in the subjects offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms",
      "Admissions will be granted strictly on the merit of qualified candidates.",
      "25% of seats at the entry point (LKG, UKG & Class I) is reserved for EWS (Economically weaker section/ BPL cardholders) and is filled as per the list sent from the BSA office.",
    ],
  },
  {
    id: 2,
    title: "Age Criteria",
    image: false,
    features: [
      "LKG – A child completing 4 years of age as of 1st April of the Academic year.",
      "UKG - A child completing 5 years of age as of 1st April of the Academic year.",
      "Class 1 – A child must be 6 years of age as of 1st April of the Academic year.",
    ],
  },
  {
    id: 3,
    title: "Documents to be submitted at the time of Admission",
    image: false,
    features: [
      "Attest a Photocopy of Birth Certificate for LKG, UKG, and class I.",
      "Attested photocopy of Progress Report of the child indicating academic performance and participation in co-curricular activities in the school last attended.",
      "Registration with CBSE, in case, admission is sought in classes X and XII on the genuine transfer of parents in addition to (2) & (3) above and a copy of the transfer order of the parents.",
      "EWS/ BPL certificate from competent authority (if applicable).",
      "Character certificate in original from the head of the previous school.",
      "Address Proof of the parents/child",
      `Income certificate of the parents'`,
      "SC/ST/OBC Certificate from the competent authority (if applicable)",
    ],
  },
];

export const curriculum = [
  {
    title: "Primary Curriculum",
    navigation: "primary",
    text: "Designed to nurture the child in all dimensions of his or her life",
    image: Primarycurriculumouter,
  },
  {
    title: "Secondary Curriculum",
    navigation: "secondary",
    text: "A skills-focused programme that provides students with a broad and balanced knowledge of key subjects",
    image: secondaryOuter,
  },
];
export const pre_primary_data = [
  {
    id: 1,
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
];
export const primary_data = [
  // {
  //   id: 1,
  //   title: "Pre-Primary Curriculum",
  //   description:
  //     "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
  //   description_image: [pre1],
  // },
  // {
  //   id: 2,
  //   title: "",
  //   description:
  //     "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
  //   description_image: [pre2],
  // },
  {
    id: 1,
    title: "Primary Curriculum",
    description:
      "The primary curriculum is a blend of both Experiential and Project-based learning where the students have hands-on experience of the learning resulting in them retaining the learning lifelong. Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected and integrated with a thematic approach making teaching and learning active and interactive.",
    description_image: [PrimaryPara2],
  },
  {
    id: 2,
    title: "Interactive Classes ",
    description:
      "Interactive classes encourage students’ participation and engagement for better learning and overall development of a child. As the primary classes are the foundational years of education, Hermannn Gmeiner School has well-equipped Interactive board classrooms, open play areas, activity corners, reading corners, etc. to reduce dependency on textbooks and increase focus on learning by interacting. Students do a lot of activities like making floor games to learn tables and other numeracy-related topics. The children take part in poem recitations, debates, speeches, and group discussions which make them more confident and learn from their peer group.",
    description_image: [PrimaryPara1],
  },
  {
    id: 3,
    title: "  Happy Classrooms",
    description:
      "A classroom should be a space where happiness takes precedence, where children are always met with a smile and with positivity. It’s a space where their wellbeing is as important as their academic achievements.Creating a support network can help to build happiness in the classroom. From friends, family and the adults within the school, children soon realise that they have important people to turn to when they need them. Feeling lonely and with nowhere to turn can do much to damage a child’s mental health and wellbeing. We create a classroom with a clear support system in place, children’s overall happiness and confidence can increase.",

    description_image: [PrimaryPara3],
  },
];
export const secondary_data = [
  {
    id: 1,
    description: `School is affiliated with Haryana Secondary and Senior Secondary Education Board (HBSE),
    Bhiwani (Haryana) . At secondary we offer following subjects:
`,
    features: [
      "Languages ( English , Hindi &amp; Urdu)",
      " Mathematics",
      "Science &amp; Technology",
      "Social Science",
      "Computer",
      "Physical Education",
    ],
    features_image: [SecondaryIneer1],
  },
  {
    id: 2,
    title: "",
    description:
      "The secondary curriculum is aimed for the holistic development of students so that they develop effective communication and critical thinking skills. The syllabus is devised in a manner which ensures the students strengthen their work habits.The subjects chosen in this curriculum prepares the students for further studies.",
  },
];
export const sr_secondary_data = [
  {
    id: 1,
    description: `The Senior Secondary School curriculum further builds on the skills and knowledge acquired by the students in the earlier classes. Life skills like social skills, thinking skills – critical, creative, decision making and problem-solving, emotional skills, and values are embedded in the curriculum. The school offers all three streams at the Senior Secondary level- Science (Medical and Non-Medical), Commerce, and Humanities.`,
    description_image: [sr_secondary_1_1],
  },
  {
    id: 2,
    title: "",
    description: `A combination of subjects helps the students in their niche and areas they want to particularly focus on. It helps them to filter out the vast range of available opportunities. Professional guidance and counselling are very crucial at this stage. The school trains its staff according to the needs and demands of the children.
The school offers academics with a subject combination as: `,
  },
  {
    description: "srsec",
  },
];
export const alumni_data = [
  {
    id: 1,
    title: "Mr. Azad",
    occupation: "Youngest Chief Flying Instructor & Trainer",
    description: `Youngest Chief Flying Instructor & Trainer
    I got the privilege of being a part of Hermannn Gmeiner School, where I not only grew physically but also emotionally and psychologically. The lessons and values taught here are embedded deep into my mind and are a part of my personality. I will cherish the experiences and memories of the school and hon'ble teachers forever. From 2006 - 2016 (10 years), I have been the Youngest Chief flying Instructor/Trainer and have trained more than 300 pilots who are now flying in different airlines in India and in foreign airlines as well. From 2017 till date, I am working as a Captain in Indigo Airlines and holding a management post as well as that of a Fleet Coordinator (Delhi Base). 
    `,
    image: nikhel,
  },
  {
    id: 2,
    title: " Ankita Lal",
    occupation:
      "Additional Senior Civil Judge in Saket District Courts, New Delhi.",
    description: `Additional Senior Civil Judge in Saket District Courts, New Delhi.
    School plays a very important part in our lives. What we are today is because of what we have learnt from our schools. I feel blessed being a part of Hermannn Gmeiner School Faridabad. The school gave me an environment which helped me excel in my academic goals. The teachers were more helpful than I had ever expected. Not only did the school cater perfectly to my academic needs but also helped me build my personality and co-curricular skills.
    `,
    image: ankita,
  },
  {
    id: 3,
    title: "Sandeep Kak",
    occupation: "General Manager - Otis Elevators",
    description: `General Manager - Otis Elevators (Bangalore, Karnataka) 
    The School is a stepping stone, responsible for the overall development of a naive and innocent child into a responsible, sensible, and law-abiding citizen. It is a home away from home for all its students and for me too, Hermannn Gmeiner School was my extended family for five years. The school was responsible for shaping my character and personality. At Hermannn Gmeiner School, I and most of my peers have always felt that teachers were our friends, philosophers, and guides. They cared about every student & always tried to give the best advice.
    `,
    image: sandeep,
  },
  {
    id: 4,
    title: "Bharat Bansal",
    occupation: "Entrepreneur in the USA",
    description: `Entrepreneur in the USA
    Hermannn Gmeiner School has been my alma mater for a long time. I have been extremely lucky to have studied here and have made lifelong friends in this school. FIGS has given a very unique perspective to me and my batch mates, due to its unique setting & approach to education in both curriculum and life skills. I wish the school all the best and will also be available to mentor any junior students if needed. 
    `,
    image: bharat_bansal,
  },

  {
    id: 5,
    title: "Subhadeep Sanyal",
    occupation: "Vice President - Omnivore Partners",
    description: `Vice President - Omnivore Partners 
    I earned a B.Sc. (Hons) in Agriculture from CCS HAU, Hisar where I was the batch topper (2005-09). I completed my Masters degree from IIM Ahmedabad, where I specialized in agribusiness during the PGDM- ABM (2009-11). The reason why I had chosen SOS Hermannn Gmeiner School over other schools in Faridabad was due to the ethos which the school had and still continues to have. The open culture and sense of collaboration among students and faculty, complete sense of freedom for a child's overall growth and the need to be rooted to one's core values were probably the greatest takeaways for me from the school.
    `,
    image: subhadeep,
  },

  {
    id: 6,
    title: "Karan Babbar",
    occupation: "Product Consultant - Accenture Service Private Limited ",
    description: `Product Consultant - Accenture Service Private Limited 
    Excellent education is one where a child's interests are nurtured and built upon. Hermannn Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
    `,
    image: karan,
  },
  {
    id: 7,
    title: "Mr. Ajay Baisla",
    occupation: "Municipal Councilor",
    description: `Product Consultant - Accenture Service Private Limited 
    Excellent education is one where a child's interests are nurtured and built upon. Hermannn Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
    `,
    image: aa,
  },
  {
    id: 8,
    title: "Dr.Rajeev Arora",
    occupation: "Specialised Dentist and Senior lecturer.",
    description: `Specialised Dentist andSenior lecturer at Manav Rachna Dental College. Served as Hony. Secratary  of Indian Dental Association Faridabad in 2012  & 2013 and as President in 2015.
    `,
    image: a,
  },
  {
    id: 9,
    title: "Anchal Gulati",
    occupation: "DD News Anchor",
    image: b,
  },
  {
    id: 10,
    title: "Jeevant",
    occupation: "Professional Photographer",
    image: c,
  },
  {
    id: 11,
    title: "Bhushan Sachdeva",
    occupation: "Chartered Accountant",
    image: d,
  },
  {
    id: 12,
    title: "Devender Chaudhary",
    occupation: "Co convener urban local body",
    image: e,
  },
];
export const admissions_data = [
  {
    id: 1,
    title: "Admission Procedure",
    class: "CLASS NURSERY TO VIII",
    description: `Admission in Hermann Gmeiner School, Palla is made without any distinction of religion,
    race, caste, birth etc.`,
    image: false,
  },
  {
    id: 2,
    title: "",

    description: `In regard to admission for students of BPL/EWS category in Class-II, it is governed by
    the RTE Act 2009 .`,
    image: false,
  },
  {
    id: 3,
    title: "",

    description: `Admissions till class V will be done on a First - come First-served Basis  and after a small
    academic test.`,
    image: false,
  },
  {
    id: 4,
    title: "",

    description: `Admission in classes VI to X will be done on the basis of tests for language, maths &amp;
    Science concepts.`,
    image: false,
  },
  {
    id: 5,
    title: "",

    description: `The Principal's Admission committee's decision will be final and binding with regard to all
    admissions.`,
    image: false,
  },
  {
    id: 6,
    title: "",

    description: `Admission is granted as and when vacancies are available in any class.`,
    image: false,
  },

  {
    id: 8,
    title: "Admission Criteria",
    class: "CLASS NURSERY TO VIII",
    subtitle: "Admission to Class-LKG:",
    description: `Students must complete 3+ years of age as on 1 st April, of the academic
    year.`,
    image: false,
  },

  {
    id: 9,
    title: "",
    subtitle: "Admission to classes II-IX:",
    description: `Report Card of the child has to be submitted with equivalent 50% in
    previous classes.
    `,
    image: false,
  },
  {
    id: 10,
    title: "",
    subtitle: "",

    description: `School leaving certificate to be collected from each and every child from Class-I and reason for
    shifting/transfer to be verified as per state education board norms.
    `,
    image: false,
  },
  {
    id: 11,
    title: "",
    description: `For admission in class IX, the student must have finished his/her regular study in class VIII. The
    admission process will be conducted only for the students who are studying in any institution
    affiliated by state governments or UT governments or other certified boards.`,

    image: false,
  },

  {
    id: 12,
    title: "",
    description: `Those seeking admission should produce the following:`,
    image: false,
    features: [
      "Birth certificate",
      "School leaving certificate",
      "Parents ID – Aadhar Card",
      "EWS/BPL card if applicable",
      "Address Proof/ Family ID/ Caste Category Certificate",
      "Child’s Photograph (Two)",
    ],
  },
  {
    id: 13,
    title: "Mode of Payment of Fees",
    description: <p>Deposit of fees at the school counter.</p>,
    image: false,
  },
  {
    id: 14,
    title: "",
    description: `The fee will be paid monthly within 10 days of the beginning of the month at the State Bank of
    India counter,Nuh ,Mewat, Harayana-122107. Parents must mention the student's name,admission number, and class with a section at the back of the cheque.`,
    image: false,
  },
  {
    id: 15,
    title: "",
    description: (
      <p>
        Cheque Payment:
        <br />
        Cheque in favour of “SOS HERMANN GMEINER SCHOOL” Payable at “SBI NUH”
        Branch
      </p>
    ),
    image: false,
  },
  {
    id: 16,
    title: "",
    description: (
      <p>
        Through Account no:
        <br />
        A/c No. 30034939016
        <br />
        IFSC Code: SBIN0000620
        <br />
        Bank State Bank of India Nuh Mewat
        <br />
      </p>
    ),
    image: false,
  },
  {
    id: 16,
    title: "",
    description: (
      <p>
        Online mode and through QR code Scan:
        <br />
        <div>
          <img style={{ width: "30vw" }} src={scanner} />
        </div>
      </p>
    ),
    image: false,
  },
];
export const facilities_data = [
  {
    id: 1,
    title: "Spacious Classrooms",
    description: `The School can boast spacious, well-ventilated, and excellently furnished classrooms to make the students learning comfortable and enjoyable. Individual tables and chairs are provided to students to make them independent and self-reliant. These specially designed tables and chairs can be brought together very easily to form circles and semicircles while the lesson is in progress to have group discussions and activities.`,
    image: [spacious_classroom],
  },
  {
    id: 2,
    title: "",
    description: `To avoid carrying heavy school bags,  a special storage facility is provided for every student in the classroom. In all classrooms, display boards are provided to display creative chart works, drawings and paintings, and craftworks. The school has the best infrastructure catering for today’s learning environment – like smart interactive classrooms with ICR facilities and the use of smart board technologies for conducive learning. This facilitates comprehensive learning and makes it enjoyable and an interesting process of learning.
    `,
    image: [spacious_classroom_2],
  },
  {
    id: 3,
    title: "STEM Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way of fostering a diverse STEM workforce. Educators are focused on improving science and mathematics instructions employing several approaches to K-12 STEM education. Students also were given opportunities to spend time with professionals in STEM fields. 
    `,
    image: [stem_lab],
  },
  {
    id: 4,
    title: "",
    description: `STEM is an abbreviation that stands for 4 closely related subjects that are Science, Technology, Engineering and Mathematics. These four theoretical and practical subjects are taught together under STEM education and starting from basics to giving precise knowledge of the subjects, professional guidelines are available at the school’s STEM lab. The students are taught by giving them real-life world problems and they are to solve these problems by theoretical and practical approach. 
    `,
    image: [stem_lab_2],
  },
  {
    id: 5,
    title: "Computer Lab",
    description: `The school has a highly sophisticated state-of-the-art computer lab. It is equipped with a computing facility with i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum.
    `,
    image: [computer_lab_1],
  },
  {
    id: 6,
    title: "",
    description: `Students are expected to use computers in their day-to-day study to meet the challenges of modern learning and to keep pace with global scenarios. Students are provided training on SAP, Tally ERP 9, MS Office-2016, and the Internet. The Institute website has been dynamically designed to update the latest development in the institute on a day to day basis.
    `,
    image: [computer_lab_2],
  },
  {
    id: 7,
    title: "Laboratories",
    description: `A scientific temperament is nurtured through fully equipped and suitably designed laboratories for Physics, Chemistry, and Biology. Students are encouraged to use the facilities for enhancing their innovative and scientific skills. It also provides an open environment for students to experiment and do research work. All these labs are provided with lecture/ seminar areas too. The three divisions of the science department ensure that students do real experiments.
    `,
    image: [laboratories],
  },
  {
    id: 8,
    title: "",
    description: `Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of the prescribed syllabus. The teachers at the physics, chemistry, and biology labs challenge them with exciting projects, giving them a strenuous time clashing with known principles and existing data. This is a place where the divine curiosity of students is at work.
    `,
    image: [laboratories_2],
  },
  {
    id: 9,
    title: "Home Science Laboratory",
    description: `Home Science is a very important subject in gaining knowledge to meet the challenge of day-to-day life with confidence. The school has a well-equipped Home-Science Lab where students are acquainted with hands-on knowledge and skills. The teacher conducts different activities in the lab like cooking, stitching, embroidery, knitting, and home management. The home science students can learn Diet Planning for regular and therapeutic needs, Floor decoration, Tie & Die, Flower Arrangement, Block Printing, Samples of different Stitches, Cooking of different recipes, and Table setting.
    `,
    image: [home_science],
  },
  {
    id: 10,
    title: "",
    description: `The home science teacher demonstrates various recipes on bakery, salads, Indian, Chinese, Mexican, and Italian cuisines. The students learn the art of cooking and hone their culinary skills by using gadgets and equipment. They also make use of these lab facilities in their practice teaching, final skill-in teaching, and craft exam.
    `,
    image: [home_science_2],
  },
  {
    id: 11,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.
    `,
    image: [arts],
  },
  {
    id: 12,
    title: "",
    description: `The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modeling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.
    `,
    image: [arts_2],
  },
  {
    id: 13,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library.  The eclectic collection that export constitutes the Library currently includes over 10,000 books. The Library resources comprise Encyclopedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills. 

    `,
    image: [library],
  },
  {
    id: 14,
    title: "",
    description: `The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals. The Digital library provides access to information not just to widen the horizons of the users' knowledge but also to support a host of their learning activities that range from book learning and newspaper reading to using e-journals and reading e-books.
    `,
    image: [library_2],
  },
  {
    id: 15,
    title: "Cultural Center (Music & Dance Room)",
    description: `This is the place where young musicians start their journey to become great composers & where the children unwind and learn the aesthetics of movement. Classical Dance (Indian & Western), Modern Dance (Indian & Western), Choreography,  Direction,  Vocal  Music (Indian and Western), Instrumental Music (Indian and Western) are all interwoven into the fabric of our educational experience.`,
    image: [cultural],
  },
  {
    id: 16,
    title: "",
    description: `Dance is an ideal means of communication to express what is too deep, too fine for words. Hermannn Gmeiner School’s students learn to choreograph and are conversant with various Indian and Western dance forms like Indian Classical, Jazz, Hip Hop, Salsa and these are some of the most eagerly awaited moments for self-expression and the students can be seen enjoying to the hilt.`,
    image: [cultural_2],
  },
  {
    id: 17,
    title: "",
    description: `Lessons in Indian and Western music generate rich cross-cultural exposure and sensitize the students to this fine art. The Department of Music and Dance is committed to excellence, innovation, learning in action, and entrepreneurship. Our mission is to bridge dance practice and scholarship and to foster interdisciplinary inquiry.`,
    image: [cultural_3],
  },
  {
    id: 18,
    title: "",
    description: `As a trained faculty, we bring a challenging range of perspectives to our teaching and research, and continually re-examine our curriculum to prepare both young and mature aspirants for careers in the ever-evolving field of Performing Arts.`,
    image: [cultural_4],
  },
];

export const results_data = [
  {
    title: "Toppers: X",
    description_image: [result_10, result_10_1, result_10_2, result_10_3],
    custom_class: "custom-img",
  },
  {
    title: "Toppers: XII",
    description_image: [result_12_1, result_12_2, result_12_3],
    custom_class: "custom-img",
  },
];

export const about_data = [
  {
    title: "About Us",
    description:
      "Hermannn Gmeiner School, Faridabad was founded in 1986 by Shri J.N Kaul who was the then President of SOS Children’s Village of India. It is one of the oldest schools in Faridabad with a bunch of exceptionally talented students and alumni. It is a co-educational school with classes from Nursery to XII (Medical, Non-Medical, Commerce, and Humanities).",
  },
  {
    title: "",
    description:
      "The school has world class infrastructure that makes it comfortable for the students to learn and grow. The faculty leaves no stone unturned to look after the development of the students. The alumini’s experiences speak for the successful careers the students established here.",
  },
  {
    title: "Our Values",
    description:
      "Hermannn Gmeiner was a man of principles and ethics. His love and compassion for children is what led him to do the social deed of education and establish SOS Children’s Village Association in 1949. Today, these institutions are active in almost 135 countries, spreading its love and knowledge.",
  },
  {
    title: "",
    description:
      "Our value and virtue is what we strive for and work our very best for all our students to learn and adapt the goodness all over. We teach our children how to excel in not only academics but every field out there and how to make a name and position for self. We teach them how to handle life’s hardships along with book and physical knowledge.",
  },
];
export const nep_data = [
  {
    id: 1,
    icon: <FaSchool />,
    description:
      "Hermannn Gmeiner School pedagogy aims to make learning more  meaningful. It makes the teaching-learning process interesting, motivating,  and stimulating. A progressive school is one that constantly caters to the demands and challenges of a rapidly changing, increasingly high tech and  global environment.",
    extra_description: ["NEP"],
  },
  {
    id: 2,
    title: "Our Curriculum aims to",
    description: "",
    features: [
      "Encourage students to think critically and solve problems through project-based learning",
      "Seamlessly integrate art into the curriculum to enhance the appreciation for aesthetic visual and performing arts.",
      "Foster holistic development through sports, i.e. Health and Physical Education.",
      "Offer activities for curricular and co-curricular enrichment via clubs and work education.",
      " Engage students in addressing real-world problems and issues important to humanity to lead change.",
      " Provide differentiated teaching-learning activities to personalize learning.",
    ],
  },
  {
    id: 3,
    title: "Key Features of the Curriculum",
    description: "",
    features: [
      "Individualized attention – low student-teacher ratio",
      "Stimulating learning environment",
      "Emphasis on all-round development and co-curricular activities",
      "Integration of ICT and Innovation in the Curriculum",
      "Parental Engagement Programmes",
      " Eco-Literacy Curriculum",
      "360-degree assessment approach",
      "Skill-based subjects Imbibing values & social skills",
    ],
  },
];
export const life_curriculum_data = [
  {
    id: 1,
    description: `Adapting the Life Skill Curriculum in the daily routine of every child at the primary stage is a very crucial part of our education. The basic hygiene importance like handwashing and daily bathing, and the value of chivalry and gratitude towards one another must be taught at their impressionable age and fortified over the years. The three components of the Life Skill Curriculum; the individual life-skill, the social skill, and the effective decision making skills are some of the eminent topics of our syllabus. To not only make our students academic geniuses, but to also refine their social and personal skills is what we aim for.`,
    description_image: [life_skill_1],
  },
  {
    id: 2,
    title: "",
    description: `We believe in testing and creating challenges for our students, so they can break their barriers and overcome their judgemental and stereotypical fears. To educate all of them about daily chores, irrespective of the gender, makes the batchmates comfortable and more open to these topics. The ten core Life Skills adopted by our education system are - 1. Self-awareness, 2. Empathy, 3. Critical Thinking, 4. Creative Thinking, 5. Decision Making, 6. Problem Solving, 7. Effective Communication, 8. Interpersonal relationship, 9. Coping with stress, 10. Coping with Emotion.
    `,
    description_image: [life_skill_2],
  },
];
export const examination_data = [
  {
    id: 1,
    title: "Assesment",
    description: `At specific periods of time, we conduct secure and stable examinations for the students of all grades to not only test their capabilities but also to help them gain self-confidence. The children are marked on the basis of their academic and co-curricular results along with their year-long behaviour and activities.`,
  },
];
export const skill_vocational_data = [
  {
    description: [
      `Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.`,
      `We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.`,
      `Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.`,
    ],
  },
  {
    title: "Classes VI-VIII",
    features: ["Mass Media", "Financial Literacy", "Handicraft"],
  },
  {
    title: "Classes  IX-X",
    features: [
      "Information Technology",
      "Banking & Insurance",
      // "Introduction to Tourism",
    ],
  },
  // {
  //   title: "Classes XI-XII",
  //   features: ["Web Application", "Financial Literacy", "Mass Media "],
  // },
  {
    title: " Special job oriented Vocational courses are (After Class XII)",
    features: [
      "Tally",
      "Data Entry Operator",
      // "Introduction to Tourism",
    ],
  },
];
export const co_curricular_data = [];
export const house_clubs_data = [
  {
    id: 1,
    title: "Sports Clubs",
    description: `The secondary curriculum is aimed for the holistic development of students so that they
    develop effective communication and critical thinking skills. The syllabus is devised in a
    manner which ensures the students strengthen their work habits. The subjects chosen in this
    curriculum prepare the students for further studies.
    `,
    image: [sports_club],
    icon: [health_club_icon],
  },
  {
    id: 2,
    title: "Health Club",
    description: `The secondary curriculum is aimed for the holistic development of students so that they
    develop effective communication and critical thinking skills. The syllabus is devised in a
    manner which ensures the students strengthen their work habits. The subjects chosen in this
    curriculum prepare the students for further studies.`,
    image: [anti_bullying],
    icon: [anti_bullying_icon],
  },
  {
    id: 3,
    title: "Environment Club",
    description: `The secondary curriculum is aimed for the holistic development of students so that they
    develop effective communication and critical thinking skills. The syllabus is devised in a
    manner which ensures the students strengthen their work habits. The subjects chosen in this
    curriculum prepare the students for further studies.
    ’.
    `,
    image: [environment_club],
    icon: [environment_club_icon],
  },

  {
    id: 7,
    title: "Social Impact Club",
    description: `The secondary curriculum is aimed for the holistic development of students so that they
    develop effective communication and critical thinking skills. The syllabus is devised in a
    manner which ensures the students strengthen their work habits. The subjects chosen in this
    curriculum prepare the students for further studies. 
    
    ’
    `,
    image: [social_impact],
    icon: [social_impact_club_icon],
  },

  // {
  //   id: 13,
  //   title: "School Disaster Management Committee (SDMC)",
  //   description: `The school disaster management committee makes students aware of the various hazards and disasters. It conducts online and offline sessions where the students are shown the videos like fire safety, earthquake readiness, etc. It conducts mock drills in school so that the children get hands-on experience of dealing with such situations.
  //   `,
  //   image: [sdmc],
  // },
  // {
  //   id: 14,
  //   title: "",
  //   description: ` It also includes making students empathetic towards their classmates, making them understand the varying needs of their fellow students and people. It imparts understanding on concepts like the buddy system, children with special needs (orthopedic, visual, or learning). With this, children become compassionate and helpful towards their classmates and people in need.`,
  //   image: [sdmc_2],
  // },
];

export const houses_school = [
  {
    id: 1,
    title: "Veer Abdul Hamid House ",
    description: `Veer Abdul Hamid is the epitome of courage and bravery. Blue colour symbolizes unity, peace,
    coolness, strength, virility, heroic spirit, loyalty and alertness hence it is Veer Abdul Hamid’s house
    colour.
    `,
    color: "#0440B6D6",
    houseImg: [blue],
  },
  {
    id: 18,
    title: "Mother Teresa House ",
    description: `Mother Teresa devoted her life to peace and service to others. Yellow colour symbolizes peace and
    happiness hence it is Mother Teresa's house colour.
    `,
    color: "#FFF616",
    houseImg: [yellow],
  },
  {
    id: 19,
    title: "Bhagat Singh House",
    description: `From the life of Shaheed Bhagat Singh, we get inspiration of love for the country and patriotism.
    Red colour symbolizes enthusiasm, good luck, exuberance, courage and new life hence it is Bhagat Singh's
    house colour.
    `,
    color: "#FF1212",
    houseImg: [red],
  },
  {
    id: 20,
    title: "Gandhi House ",
    description: `It is named after an ideal, the father of the nation and inspiration for our youth, Mohandas Karamchand Gandhi
    that ignites the spirit in the very soul of our students for moving ahead with great zeal and passion. Green colour
    symbolizes faith, fertility, happiness, prosperity and progress hence it is Gandhi’s house colour.
    `,
    color: "#24EA03F5",
    houseImg: [green],
  },
];
export const student_council_data = [
  {
    id: 1,
    description: `Unify the student body through social activities and community opportunities.
    Promote civic responsibility, leadership, scholarship and human relations within the
    student body. Provide aid to the students, the school and the community. Host school
    functions and events.
    `,
    description_image: [student_council_1],
  },
];
export const disclosure_data_general = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "NAME OF THE SCHOOL",
    description: "HERMANN GMEINER SCHOOL, PALLA (NUH)",
  },
  {
    id: 3,
    title: "2",
    name: "AFFILIATION NO (IF APPLICABLE)",
    description: "-",
  },
  {
    id: 4,
    title: "3",
    name: "SCHOOL CODE (IF APPLICABLE)",
    description: "20212",
  },
  {
    id: 5,
    title: "4",
    name: "COMPLETE ADDRESS WITH PIN CODE",
    description: "PALLA, NUH (HARYANA)-122107",
  },
  {
    id: 6,
    title: "5",
    name: "PRINCIPAL NAME",
    description: "MR. Mohd. Aslam",
  },
  {
    id: 7,
    title: "6",
    name: "PRINCIPAL QUALIFICATION",
    description: " M.A. (ENG, POL.SC.), B.ED.",
  },
  {
    id: 8,
    title: "7",
    name: "SCHOOL EMAIL ID",
    description: "Principal.Pallanuh@hermann-gmeiner.edu.org",
  },
  {
    id: 9,
    title: "8",
    name: "CONTACT DETAILS (LANDLINE/MOBILE)",
    description: "+91-8979870545",
  },
];
export const disclosure_data_documents = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE",
  },
  {
    id: 2,
    title: "1",
    name: "COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY",
    description: require("../src/pdf/affiliation.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE",
    description: "",
  },
  {
    id: 4,
    title: "3",
    name: "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
    description: require("../src/pdf/noc.pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
    description: require("../src/pdf/society.pdf"),
  },
  {
    id: 6,
    title: "5",
    name: "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
    description: "",
    customcomponent: require("../src/pdf/building.pdf"),
  },
  {
    id: 7,
    title: "6",
    name: "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
    description: "",
    customcomponent: require("../src/pdf/fire.pdf"),
  },
  {
    id: 8,
    title: "7",
    name: "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/ UPGRADATION/ EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL",
    description: "",
    customcomponent: require("../src/pdf/affilation.pdf"),
  },
  {
    id: 9,
    title: "8",
    name: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
    description: "",
    customcomponent: require("../src/pdf/HYGIENE.pdf"),
  },
];
export const disclosure_data_results = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE",
  },
  {
    id: 2,
    title: "1",
    name: "FEE STRUCTURE OF THE SCHOOL",
    customcomponent: require("../src/pdf/FeeStructure1.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "ANNUAL ACADEMIC CALENDAR",
    customcomponent: require("../src/pdf/Academic Calendar 2023-24.pdf"),
  },
  {
    id: 4,
    title: "3",
    name: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
    customcomponent: require("../src/pdf/LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC).pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
    description: require("../src/pdf/PTA.pdf"),
  },
  // {
  //   id: 6,
  //   title: "5",
  //   name: "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
  //   description: "",
  // },
  {
    id: 6,
    title: "5",
    name: "HOLIDAYS",
    customcomponent: require("../src/excel/Holiday List-Students-2024 (1).docx"),
  },
  {
    id: 7,
    title: "6",
    name: "RESULT",
    customcomponent: require("../src/excel/RESULT_HERMANN GMEINER SCHOOL, PALLA.xlsx"),
  },
];
export const teaching_Staff = [
  {
    id: 1,
    title: "S.NO",
    name: "Name",
    designation: "Designation",
    qualification: "Qualification",
  },
  {
    id: 2,
    title: "1",
    name: "Mohd. Aslam Ansari",
    designation: "Headmaster",
    qualification: "B.Ed, M. A in English and Political Science",
  },
  {
    id: 3,
    title: "2",
    name: "Ms. Rani Gupta ",
    designation: "PRT",
    qualification: "B.A, N.T.T",
  },
  {
    id: 4,
    title: "3",
    name: "Ms. Rekha Dubey",
    designation: "PRT",
    qualification: "B.A, N.T.T",
  },
  {
    id: 5,
    title: "4",
    name: "Mr. Saroj Kumar Nayak ",
    designation: "PRT",
    qualification: "B.A, B.E.D, PGDCA, MSW ",
  },
  {
    id: 6,
    title: "5",
    name: "Mr. Kuldeep Khokher ",
    designation: "PRT ",
    qualification: "M.A(Hindi) B.Ed ",
  },
  {
    id: 7,
    title: "6",
    name: "Ms. Laxmi Devi ",
    designation: "PRT",
    qualification: "B.A, NTT ",
  },

  {
    id: 8,
    title: "7",
    name: "Ms. Preeti Singhal",
    designation: "PRT",
    qualification: "M.A(English) B.Ed ",
  },
  {
    id: 9,
    title: "8",
    name: "Mr. Arvind Kumar Pandey ",
    designation: "PRT",
    qualification: "M.A(Sanskrit) B.Ed ",
  },
  {
    id: 10,
    title: "9",
    name: "Mr. Deepak Pandey ",
    designation: " PRT ",
    qualification: "M.A(Hindi) B.Ed, CTET  ",
  },
  {
    id: 11,
    title: "10",
    name: "Ms. Rabina Khan  ",
    designation: "PRT",
    qualification: "M.Com, B.Ed ",
  },
  {
    id: 12,
    title: "11",
    name: "Ms. Tabassum ",
    designation: "PRT",
    qualification: "M.Com, JBT, B.Ed  ",
  },
  {
    id: 13,
    title: "12",
    name: "Ms. Tehrima Khan",
    designation: "PRT",
    qualification: "B.S.C, B.Ed ",
  },
  {
    id: 14,
    title: "13",
    name: "Mr. Manish Kumar ",
    designation: "PRT (Computer) ",
    qualification: "B.A, Computer Diploma",
  },
  {
    id: 15,
    title: "14",
    name: "Ms. Kalsum ",
    designation: "PRT",
    qualification: "B.A, JBT ",
  },
  {
    id: 16,
    title: "15",
    name: "Ms. Afshan Khan ",
    designation: "PRT",
    qualification: "B.A, M.A (Urdu), JBT ",
  },
  {
    id: 17,
    title: "16",
    name: "Ms. Neefa Khan ",
    designation: "PRT",
    qualification: "B.Sc , B.Ed ",
  },
  {
    id: 18,
    title: "17",
    name: "Ms. Zaheeda ",
    designation: "TGT (S. St.) ",
    qualification: "M.A(Pol.Sc), B.Ed ",
  },
  {
    id: 19,
    title: "18",
    name: "Mr. Harshay Singh ",
    designation: "PET ",
    qualification: "B.A, B.P.Ed ",
  },

  {
    id: 20,
    title: "19",
    name: "Mr. Kashmiri Lal ",
    designation: "TGT (S. St.) ",
    qualification: "B.A, M.A (History), B.Ed ",
  },
  {
    id: 21,
    title: "20",
    name: "Mr. Anil Kumar ",
    designation: "TGT(Maths)",
    qualification: "B.S,C, B.Ed ",
  },
  {
    id: 22,
    title: "21",
    name: "Ms. Sarita Sharma",
    designation: "TGT (Hindi)",
    qualification: "M.A (Hindi), B.Ed ",
  },
  {
    id: 23,
    title: "22",
    name: "Ms. Neelam Sagar",
    designation: "TGT (Science)",
    qualification: "M.S.C(Zoology), B.Ed ",
  },
  {
    id: 24,
    title: "23",
    name: "Ms. Shabana",
    designation: "TGT(Urdu)",
    qualification: "MA(Urdu), B.Ed",
  },
];

export const co_activity = [
  {
    id: 1,
    title: "S.NO",
    name: "Name",
    designation: "Designation",
    qualification: "Qualification",
  },
  {
    id: 2,
    title: "1",
    name: "Mr. GL Pandita",
    designation: "PET",
    qualification: "B.A., B.PED",
  },
];
export const admin_staff = [
  {
    id: 1,
    title: "S.NO",
    name: "Name",
    designation: "Designation",
    qualification: "Qualification",
  },
  {
    id: 2,
    title: "1",
    name: "Mr. Shaheed Ahamed ",
    designation: "Helper Gardener ",
    qualification: "LLB, BCA, GNIIT",
  },
  {
    id: 3,
    title: "2",
    name: "Mr. Lateef ",
    designation: "Helper ",
    qualification: "BA",
  },
  {
    id: 4,
    title: "3",
    name: "Mr. Krishan Kumar Yadav ",
    designation: "Co-Worker-Security ",
    qualification: "BA",
  },
  {
    id: 5,
    title: "4",
    name: "Mr. Pawan ",
    designation: "Jr. Co-Worker-Finance ",
    qualification: "BA",
  },
  {
    id: 6,
    title: "5",
    name: "Ms. Beena Devi  ",
    designation: "Helper ",
    qualification: "BA",
  },
  {
    id: 7,
    title: "6",
    name: "Mr. Mahesh Chand ",
    designation: "Helper ",
    qualification: "BA",
  },
  {
    id: 8,
    title: "7",
    name: "Mr. Viney ",
    designation: "Office Assistant ",
    qualification: "BA",
  },
];
export const disclosure_data_staff = [
  {
    id: 1,
    title: "S.NO",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "PRINCIPAL",
    description: "Mr. MOHD. ASLAM",
  },
  {
    id: 3,
    title: "2",
    name: "TOTAL NUMBER OF TEACHERS",
    name1: "PGT",
    name2: "TGT",
    name3: "PRT",
    description: "22",
    description1: "-",
    description2: "07",
    description3: "15",
  },
  {
    id: 4,
    title: "3",
    name: "TEACHERS SECTION RATIO",
    description: "1.30",
  },
  {
    id: 5,
    title: "4",
    name: "DETAILS OF VTC Tally Trainer",
    description: "1",
  },
  // {
  //   id: 6,
  //   title: "5",
  //   name: "DETAILS OF COUNSELLOR AND WELLNESS TEACHER",
  //   description: "NISHTHA JATWANI MANOCHA",
  // },
];
export const disclosure_data_results_10 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },
  {
    id: 2,
    title: "1",
    extra_title: "2022",
    name: "37",
    description: "27",
    extra_description: "71.42",
  },
  {
    id: 3,
    title: "2",
    extra_title: "2023",
    name: "40",
    description: "37",
    extra_description: "92.50",
  },
  {
    id: 4,
    title: "3",
    extra_title: "2024",
    name: "45",
    description: "45",
    extra_description: "100",
  },
  
];
export const disclosure_data_results_12 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },
  {
    id: 2,
    title: "1",
    extra_title: "2021",
    name: "95",
    description: "95",
    extra_description: "100%",
  },
];
export const disclosure_data_school = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)",
    description: "19374.3",
  },
  {
    id: 3,
    title: "2",
    name: "NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)",
    description: "41.14",
  },
  {
    id: 4,
    title: "3",
    name: "NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)",
    description: "",
    description1: "41.14",
  },
  {
    id: 5,
    title: "4",
    name: "INTERNET FACILITY",
    description: "YES",
  },
  {
    id: 6,
    title: "5",
    name: "NO. OF GIRLS TOILETS",
    description: "02",
  },
  {
    id: 7,
    title: "6",
    name: "NO. OF BOYS TOILETS",
    description: "02",
  },
  {
    id: 8,
    title: "7",
    name: "LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL",
    description: "",
  },
];
export const trip_data = [
  {
    id: 1,
    title: "Educational Trips",
    description: `The secondary curriculum is aimed for the holistic development of students so that they
    develop effective communication and critical thinking skills. The syllabus is devised in a
    manner which ensures the students strengthen their work habits. The subjects chosen in this
    curriculum prepare the students for further studies.`,
    description_image: [trip_cover],
  },
  {
    id: 2,
    title: "Field Visits:",
    description: `There are certain topics or chapters which can be explained effectively by visiting such places and
    observing live happening /working at that place. School organizes Field visits with core purpose of
    making understanding easier through experiential learning. Learning becomes more powerful
    when is shifted to real life situation from four walls of classroom. Our students visited time to
    time Mewat Engg. College conducted by HAREDA and also participating in programs like
    awareness rallies on Energy and water Conservation, Voters day, constitution day , cleanliness
    and on ‘Aazadi ka Amrit Mahotsav- Har Ghar Tiranga’. They also visited places like Farms, Bus
    Stand, Near by historical Musa Masjid , Gram Panchayat office etc. as a part of field visit every
    year.`,
    description_image: [trip_cover_1],
  },
  {
    id: 1,
    title: "Trip to Agra",
    description: `Our school went to Agra for an educational tour on 17 th December 2022.
    In this tour, 100 Children from who were from class 5 th to 10 th went along
    with 20 teachers.
    In this tour they visited Tajmahal and Agra Fort and the children got
    information about these two historical monuments.`,
    description_image: [trip_cover_3],
  },
];
export const ncc_scouts_guides_data = [
  {
    id: 1,
    description: `To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scout and Guides for making them capable, sensitized to work for the betterment of society and for enhancing their lifestyle and leadership qualities.`,
  },
  {
    id: 2,
    title: "NCC",
    description: `The school is going to start NCC in the next session. 4 BN Haryana has given 20 vacancies to our school.  Imbibing discipline and leadership qualities in students is one of the main motives behind the admission of a large number of students as NCC cadets. The cadets are mentally and physically trained to become productive citizens of the nation.`,
    description_image: [ncc_1],
  },
  // {
  //   id: 3,
  //   title: "School band",
  //   description: `Music has always proven to be one of the best therapies and to train the students in all aspects, our school has a special band. Training and understanding of various musical instruments, importance of teamwork and knowledge of musical notes are provided to the students. The school band often performs in different events and functions of the schools for which the equipment is both provided as well as allowed to bring on their own.
  //   `,
  //   description_image: [school_band_1],
  // },
  // {
  //   id: 4,
  //   title: "Scouts and Guides",
  //   description: `All eligible and interested students are given fun but important teachings of scouts and guides. It helps them grow physically, mentally, socially and intellectually. Uniforms and other requirements that we provide are a must to bring in the quality of discipline among them. All of them are expected to learn the rules and regulations by heart and implement the same in their actual lives, hence making them stronger and more confident.`,
  //   description_image: [scouts_1],
  // },
];
export const transportation_data = [
  {
    title: "",
    description: `The school provides an optional facility for students to commute from home to school. This service is offered from various places of Faridabad and Delhi to the school. The buses are operated by experienced & trained drivers/attendants to monitor the children’s safe journey. The school tries to provide safe and comfortable commuting for students from home to school and back. Our buses are specially equipped and fitted with speed governors, rear cameras, first-aid equipment, fire extinguishers, etc.`,
    description_image: [bus_1],
    custom_class: "custom-img",
  },
];
export const award_text_data = [
  {
    title: "",
    description: [
      "Hermannn Gmeiner School proudly highlights its achievements and presents the best of its students, whether they are excelling academically, athletically or in service to the community. We hope to inspire more students to excel, their achievements ultimately make the school become stronger.",
      "By highlighting student achievements, we aim to eliminate self-doubt and build confidence in our students that hard work is important and is making an impact. In turn, this confidence can carry over to other facets of their young lives, including making smart choices when faced with several challenges as they grow up.",
    ],
  },

  {
    features: [
      "Global School Award for Innovation in Pedagogical Practices in 2020",
      "Best Teaching Excellence Award by International School Award held at Dubai in 2018",
      "Indian School Award in 2018",
      "National School Award 2018",
      "Global Sustainable School Award 2017 as a recognition of its efforts towards community service and environment",
    ],
  },
];
// the long list of awards such as
//stand testimony to the fact that the school never rests on its laurels.

export const awards_data = [
  {
    title: "",
    image: award_8,
  },
  {
    title: "",
    image: award_10,
  },
  {
    title: "",
    image: award_9,
  },
  {
    title: "",
    image: award_1,
  },
  {
    title: "",
    image: award_2,
  },
  {
    title: "",
    image: award_3,
  },
  {
    title: "",
    image: award_4,
  },
  {
    title: "",
    image: award_5,
  },
  {
    title: "",
    image: award_6,
  },
  {
    title: "",
    image: award_7,
  },
];
export const news_text_data = [
  {
    description: `Reputed newspapers have on several occasions, published the events and happenings at
    Hermann Gmeiner School Palla We humbly acknowledge this recognition and strive to be a
    responsible school and be dedicated towards the community welfare.`,
  },
];
export const news_data = [
  {
    title: "",
    image: media1,
  },
  {
    title: "",
    image: media2,
  },
  {
    title: "",
    image: media3,
  },
  {
    title: "",
    image: media4,
  },
  {
    title: "",
    image: media,
  },
  {
    title: "",
    image: m4,
  },
  {
    title: "",
    image: m3,
  },
  {
    title: "",
    image: m2,
  },
  {
    title: "",
    image: m1,
  },
  {
    title: "",
    image: m6,
  },
  {
    title: "",
    image: m7,
  },
  {
    title: "",
    image: m8,
  },
  {
    title: "",
    image: m9,
  },
  {
    title: "",
    image: m10,
  },
  {
    title: "",
    image: m11,
  },
];
export const alumni_text_data = [
  {
    id: 1,
    title: "Mr. Azad",
    description: `I, Mr Azad S/o Mr Yusuf, resident of Rehna village, feel happy to share with
    you that I have been class X pass out student of SOS HGS Palla Nuh in 2011-12.
    After passing class XII I have joined Indian Army. I am grateful to HGS Palla and
    the teachers for their co-operation. What I am today, this is just because of the
    root level conceits of morality and hard work inculcated by the school.
    Brief Profile One Of Our Alumnae,
    `,
    image: Azad,
  },
  {
    id: 2,
    title: " Ms. Nirmala",
    description: `I, Nirmala D/o Mr Rajender, resident of Beevan village, feel happy to share
    with you that I have been class X pass out student of SOS HGS Palla Nuh. I have
    been elected Surpanch of the village Beevan , Nuh. I can never forget the sweet
    memory of my School Education. The qualities of leadership which I learnt from
    school developed later on and turned me as surpuch of the village.
    `,
    image: Nirmala,
  },
  {
    id: 3,
    title: "Mr. Akram ",
    description: `Doing MBBS in Russia -I,Akram, resident of village Palla ,started my education from SOS,HGS
    ,Palla Nuh from 1 s t standard onwards. I am doing MBBS form Russia this
    time. I completed my my class 10 th in the session 2013-14 from HGS,Palla
    Nuh.As for my background is concerned, my family has been illiterate
    and really it was typical for me to step for education. This only happened
    jus t because of the HGS, school in our village. If it had not been existed
    here in my village, I would have not been succeeded and built my career.
    I am really very thankful for the principal ,teaching staff and all those
    who supported me in the school.
    
    `,
    image: Akram,
  },
];
export const sports_data = [
  {
    id: 1,
    title: "Sports",
    description: `Having an active lifestyle is key to living a healthy and fit life. To ensure that our students are not only just engrossed in studies, to promote a spirit of sportsmanship and camaraderie, we encourage the students to participate in regularly held intra and inter school events within and outside of the Hermannn Gmeiner School community. Taekwondo, Football, Badminton, Lawn Tennis, Table Tennis, Cricket, Chess and Yoga`,
  },
];

export const faculty_data = [
  {
    title: "Faculty & Staff",
    description: `Hermannn Gmeiner School Faridabad has a very experienced, knowledgeable, 
    well-qualified and dedicated Staff, so much so that they are conscious of their 
    responsibility of being the most important block in the foundation of the Nation 
    as well as the Society.`,
    description_image: [faculty_1, faculty_2],

    custom_class: ["faculty-img"],
    extra_description: [
      `Their self-discipline is an example in itself, which always drives them to work as 
    a well-knit team to make all possible endeavour together for the holistic growth 
    of students as well the institution. They are enthusiastic, dedicated, 
    conscientious and highly motivated to adapt to new innovative ideas and well 
    versed with modern education systems.`,
      `The valuable contribution of all the Faculty members is well manifested in the 
    spectacular achievements of our students in the scholastic as well as co-
    scholastic discipline.`,
      `The Teachers grouped under various subject-wise Faculties are put under the 
    guidance of the most experienced Faculty heads. All of them always put their 
    heads together to devise improvised methodology to deliver their best to 
    facilitate the young evolving minds. This significantly contributes in promoting 
    the problem solving skills of students to enable them to grow as useful citizens.`,
    ],
  },
];

export const events_data = [
  {
    title: "Annual Sports meet",
    description: `School organizes Annual Sports Meet every year with aim of 100% participation from all
    students. Teachers ensure that each student participate in this annual event. Such meet
    encourage sportsmanship in students, build confidence and learn to work as a team.
    School invites parents and local well known personalities are also invited.`,
    description_image: [events_cover],
  },
  {
    title: `Various Inter HGS Competitions`,
    description: `The school encourages students to participate in various opportunities to express
    themselves by taking parts in inter HGS competitions in area of Sports, Literary, Art and
    Science. It helps to express them, to develop their communication skills, ethical and
    other moral values.`,
    description_image: [inter],
  },

  {
    title: `Other Important Events / Days celebration at School`,
    description: `School celebrates various important festivals like Raksha Bandhan, Janmasthami,
    Navratri, Diwali, Christmas, Holi etc in order to infuse strong connection with our culture
    and tradition.`,
    description_image: [teacher],
  },
  {
    title: "",
    description: `School also celebrate various important national or international days like International Yoga day ,
    Environment Day , Independence Day , Republic Day, Education day ,National Sports Day ,Energy
    conservation day in school through various ways so that students realise true purpose of
    celebrating that particular day. `,
    description_image: [republic_2],
  },
];
